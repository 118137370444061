<template>
  <Ethereum
    :callback="initializePage"
  />

  <!-- Display a dialog that allows the user to purchase tokens. -->
  <Dialog :visible="displayModal" :modal="true" :closable="false">
    <img class="dialog-image" :src="pool.image" />
    <div class="name">
      {{ pool.name }}
    </div>
    <div class="token-conversion">
      {{ `${formatNumber(ethers.utils.formatEther(pool.price), 5)} ${pool.tokenWhichPurchases.symbol} = 1 ${pool.tokenForSale.symbol}` }}
    </div>
    <div class="label">Desired Amount:</div>
    <div class="input-wrapper">
      <input
        class="number-input"
        type="number"
        v-model="amount"
        :placeholder="0.0"
      />
      <div class="input-eth-label">ETH</div>
    </div>
    <div class="max-allocation" @click.stop="setMaxAllocation">
      Max Allocation:
      {{ formatNumber(ethers.utils.formatEther(personalMaxAllocation.mul(pool.price).div(ethers.utils.parseEther('1'))), 4) }} ETH
    </div>

    <!-- Display a button to close the modal. -->
    <div class="modal-button-wrapper">
      <Button
        label="Cancel"
        class="p-button-text"
        @click="() => showModal(false)"
      />

      <!-- Display a button to purchase tokens. -->
      <Button
        label="Join Pool"
        class="p-button-new"
        autofocus
        :loading="isLoading"
        @click="joinPool"
      />
    </div>
  </Dialog>

  <!-- Display a dialog confirming the user's transaction is sent. -->
  <!-- TODO: this dialog should not trigger a refresh of the page. -->
  <Dialog
    :visible="displaySecondModal"
    :modal="true"
    :closable="true"
    class="second-modal-wrapper"
  >
    <div class="name">
      <h3>Transaction Sent</h3>
      <p>Check "Your allocations" to see the transaction.</p>
    </div>
    <Button label="Got it." class="button p-mr-3" @click="refresh()" />
  </Dialog>

  <!-- Display details about the pool. -->
  <div class="p-col-12 p-px-3 p-px-md-6 container p-text-left">
    <div v-if="pool" class="p-my-12">
      <div class="p-grid">
        <div class="p-col-12 p-md-6">
          <img class="img" :src="pool.image" />
          <h3>{{ pool.name }}</h3>
          <div class="contract">{{ address }}</div>

          <!-- Display a button that allows the user to join the pool. -->
          <div class="p-mt-4">
            <div class="buttons-wrapper">
              <Button
                v-if="!pool.finished && canParticipate"
                @click="showModal(true)"
                :loading="isLoading"
                class="button p-mr-3"
                icon="pi pi-plus"
                iconPos="left"
                label="Join Pool"
              />

              <!-- Disable the button if the user may not participate. -->
              <Button
                v-else
                :loading="isLoading"
                class="button p-mr-3"
                icon="pi pi-plus"
                iconPos="left"
                label="Join Pool"
                disabled
              />

              <!-- Display a link to the contract on Etherscan. -->
              <a
                rel="noopener nofollow"
                target="_blank"
                :href="`https://etherscan.io/address/${address}`"
                class="button p-button p-button-outlined"
              >
                View on Etherscan
              </a>
            </div>
          </div>

          <!-- Display badges about the pool's current status. -->
          <div class="status p-d-flex p-ai-center">
            <Badge
              v-if="!pool.finished && !pool.enabled"
              value="Soon"
              class="p-badge-info"
            />
            <Badge
              v-if="saleProgress.lt(100) && !pool.finished && pool.enabled"
              value="LIVE"
              class="p-badge-danger"
            />
            <Badge
              v-if="!saleProgress.lt(100) && !pool.finished"
              value="Filled"
              class="p-badge-filled"
            />
            <Badge
              v-if="pool.finished"
              value="Closed"
              class="p-badge-closed"
            />
          </div>
        </div>

        <!-- Display details about the sale and its progress. -->
        <div class="p-col-12 p-md-6 p-mt-6 p-mt-md-0">
          <div class="card p-p-4">
            <h5>
              Swap Amount
              <span class="rate">
                {{ `${formatNumber(ethers.utils.formatEther(pool.price), 6)} ${pool.tokenWhichPurchases.symbol} = 1 ${pool.tokenForSale.symbol}` }}
              </span>
            </h5>
            <div class="value">
              {{ `${formatNumber(ethers.utils.formatEther(pool.tokensAvailable), 0)} ${pool.tokenForSale.symbol}` }}
              <span>Use ETH to swap</span>
            </div>

            <!-- Display a progress bar for the sale. -->
            <h5>
              Swap Progress
            </h5>
            <ProgressBar
              :value="saleProgress.toNumber()"
            />
            <div class="p-d-flex p-jc-between">
              <div class="progress-value">
                {{ `${formatNumber(saleProgress, 0)}%` }}
                <span>
                  (Min 75%)
                </span>
              </div>
              <div class="progress-value">
                <span>
                  {{ `${formatNumber(ethers.utils.formatEther(pool.currentRaise), 2)} / ${formatNumber(ethers.utils.formatEther(pool.totalRaise), 2)} ETH` }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Display information about the pool. -->
  <div v-if="pool" class="p-col-12 footer p-mt-auto">
    <div class="container p-px-3 p-px-md-6 p-text-left">
      <TabView class="tabview">
        <TabPanel header="Pool Details">
          <h2>Pool Details</h2>
          <div class="p-grid">
            <div class="p-col-12 p-md-6">
              <div class="table">
                <div class="tr">
                  <div class="td" colspan="2">Pool information</div>
                </div>

                <div class="tr">
                  <div class="td">Token Distribution</div>
                  <div class="td">{{ pool.distribution }}</div>
                </div>

                <div class="tr">
                  <div class="td">Min. Allocation</div>
                  <div class="td">
                    <div>{{ formatNumber(ethers.utils.formatEther(pool.minAllocation), 2) }} ETH</div>
                    <div class="subtext">
                      ({{ formatNumber(ethToUsd(pool.minAllocation), 2) }} USD)
                    </div>
                  </div>
                </div>

                <div class="tr">
                  <div class="td">Max. Allocation</div>
                  <div class="td">
                    <div>{{ formatNumber(ethers.utils.formatEther(pool.maxAllocation), 3) }} ETH</div>
                    <div class="subtext">
                      ({{ formatNumber(ethToUsd(pool.maxAllocation), 2) }} USD)
                    </div>
                  </div>
                </div>

                <div class="tr">
                  <div class="td">Soft Cap</div>
                  <div class="td">
                    <div>{{ formatNumber(ethers.utils.formatEther(pool.softCap), 2) }} ETH</div>
                    <div class="subtext">
                      ({{ formatNumber(ethToUsd(pool.softCap), 2) }}
                      USD)
                    </div>
                  </div>
                </div>

                <div class="tr">
                  <div class="td">Access type</div>
                  <div class="td">
                    {{ !pool.isWhitelist ? 'Public' : 'Private' }}
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12 p-md-6 p-mt-3 p-mt-md-0">
              <div class="table">
                <div class="tr">
                  <div class="td" colspan="2">Token information</div>
                </div>
                <div class="tr">
                  <div class="td">Name</div>
                  <div class="td">{{ pool.name }}</div>
                </div>
                <div class="tr">
                  <div class="td">Address</div>
                  <div
                    class="td-address"
                    @click="navigateToAddress(pool.tokenForSale.address)"
                  >
                    {{ pool.tokenForSale.address }}
                  </div>
                </div>

                <div class="tr">
                  <div class="td">Total Supply</div>
                  <div class="td">{{ formatNumber(ethers.utils.formatEther(pool.tokenForSale.totalSupply), 0) }}</div>
                </div>

                <div class="tr">
                  <div class="td">Decimals</div>
                  <div class="td">{{ pool.tokenForSale.decimals }}</div>
                </div>

                <div class="tr">
                  <div class="td">Symbol</div>
                  <div class="td">{{ pool.tokenForSale.symbol }}</div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel header="About the Project">
          <h2>
            <img style="max-height:100px;" :src="pool.descriptionImage" />
          </h2>
          <p>
            <a
              class="social__link"
              :href="pool.twitterLink"
              title="twitter"
              target="_blank"
              rel="noopener"
            >
              <svg width="30" height="30" viewBox="0 0 30 30">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.7 7.07c-.95.42-1.96.7-3 .82A5.25 5.25 0 0027 5a10.45 10.45 0 01-3.32 1.27 5.23 5.23 0 00-8.9 4.77A14.84 14.84 0 014 5.57a5.21 5.21 0 001.62 6.98 5.21 5.21 0 01-2.37-.65v.06a5.23 5.23 0 004.2 5.13c-.78.21-1.59.24-2.37.1a5.23 5.23 0 004.88 3.62 10.5 10.5 0 01-7.74 2.17 14.79 14.79 0 008.02 2.35c9.61 0 14.87-7.97 14.87-14.88 0-.22 0-.45-.02-.67 1.03-.74 1.91-1.66 2.61-2.7v-.01z"
                  fill="#7112ff"
                ></path>
              </svg>
            </a>
            <a
              class="social__link"
              :href="pool.telegramLink"
              title="telegram"
              target="_blank"
              rel="noopener"
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                height="30"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M437.019,74.981C388.667,26.628,324.379,0,256,0S123.333,26.628,74.981,74.981C26.628,123.333,0,187.621,0,256
      s26.628,132.667,74.981,181.019C123.333,485.372,187.621,512,256,512s132.667-26.628,181.019-74.981S512,324.379,512,256
      S485.372,123.333,437.019,74.981z M256,495.832C123.756,495.832,16.168,388.244,16.168,256S123.756,16.168,256,16.168
      S495.832,123.756,495.832,256S388.244,495.832,256,495.832z"
                  fill="#7112ff"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M400.989,98.637c-2.482-1.87-5.817-2.15-8.577-0.722L79.822,259.599c-2.702,1.397-4.391,4.194-4.371,7.236
      c0.02,3.042,1.747,5.815,4.469,7.176l81.764,40.88v81.006c0,2.12,0.721,4.218,2.18,5.757c1.614,1.703,3.759,2.557,5.905,2.557
      c1.352,0,2.704-0.338,3.927-1.018l93.544-51.969l71.597,30.684c1.523,0.653,3.209,0.923,4.839,0.619
      c3.355-0.627,5.849-3.197,6.485-6.372l18.115-90.577c1-5.003-2.826-9.67-7.928-9.67c-3.854-0.002-7.171,2.718-7.927,6.496
      l-16.162,80.808l-66.295-28.412c-2.297-0.985-4.923-0.85-7.111,0.363l-85,47.223v-72.492c0-3.062-1.73-5.86-4.469-7.231
      l-72.015-36.007l283.53-146.654l-24.605,123.023c-1,5.003,2.826,9.67,7.928,9.67c3.853,0,7.171-2.721,7.928-6.499l27.903-139.517
      C404.662,103.633,403.471,100.506,400.989,98.637z"
                  fill="#7112ff"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M348.576,164.757c-2.541-3.216-7.089-4.024-10.581-1.873l-140.126,86.232c-2.391,1.471-3.847,4.078-3.847,6.885v86.232
      c-0.001,3.75,2.577,7.006,6.225,7.868c0.621,0.147,1.244,0.218,1.86,0.218c3.007,0,5.837-1.686,7.228-4.47l31.75-63.5
      l106.863-106.863C350.847,172.586,351.117,167.975,348.576,164.757z M228.725,271.842c-0.613,0.614-1.125,1.324-1.513,2.101
      l-17.022,34.043v-47.468l77.636-47.777L228.725,271.842z"
                  fill="#7112ff"
                />
              </svg>
            </a>
            <a
              class="social__link"
              :href="pool.telegramSecondLink"
              title="telegram"
              target="_blank"
              rel="noopener"
            >
              <svg width="30" height="30" viewBox="0 0 30 30">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 27.5a12.5 12.5 0 110-25 12.5 12.5 0 010 25zm-3.89-11.04h.02l1.09 3.58c.14.39.33.46.56.43.24-.03.36-.16.52-.3l1.48-1.44 3.19 2.36c.58.32 1 .15 1.14-.54l2.07-9.78c.23-.91-.17-1.28-.87-.98l-12.17 4.7c-.83.33-.82.8-.15 1l3.12.97z"
                  fill="#7112ff"
                ></path>
              </svg>
            </a>
            <a
              class="social__link"
              :href="pool.whitePaperLink"
              title="whitePaper"
              target="_blank"
              rel="noopener"
            >
              <img src="/images/whitepaper.svg" />
            </a>
          </p>
          <p v-for="(descriptionBlock, index) in pool.descriptions" :key="index">
            {{ descriptionBlock }}
          </p>
        </TabPanel>

        <TabPanel header="Your allocations">
          <div v-if="allocations.length > 0">
            <h2>Your allocations</h2>
            <div class="table-allocations">
              <div class="table-header allocations">
                <div>#</div>
                <div>Action</div>
                <div>Token Allocation</div>
                <div>ETH Amount</div>
                <div>Date</div>
              </div>
              <div class="table-row allocations" v-for="(allocation, index) in allocations" :key="index">
                <div>{{ index + 1 }}</div>

                <!-- If the pool is finished, activate the claim button. -->
                <div>
                  <Button
                    v-if="!pool.finished"
                    label="Wait for pool to finish"
                    class="p-button-allocation"
                    disabled
                  />
                  <Button
                    v-else-if="pool.finished && !allocation.claim"
                    label="Claim"
                    class="p-button-allocation"
                    autofocus
                    :loading="isLoading"
                    @click="claimToken"
                  />
                  <Button
                    v-else
                    label="Claimed"
                    class="p-button-allocation"
                    disabled
                  />
                </div>

                <!-- Display the acquired tokens and spent Ether. -->
                <div>
                  {{ formatNumber(ethers.utils.formatEther(allocation.swap.args.amt), 2) }}
                </div>
                <div>
                  {{ formatNumber(ethers.utils.formatEther(allocation.swap.args.amount), 3) }}
                </div>

                <!-- If there is no claim event, link to the swap event. -->
                <div v-if="!allocation.claim">
                  <a :href="`https://etherscan.io/tx/${allocation.swap.transactionHash}`">
                    View Etherscan
                  </a>
                </div>

                <!-- If there is a claim event, link to it. -->
                <div v-if="allocation.claim">
                  <a :href="`https://etherscan.io/tx/${allocation.claim.transactionHash}`">
                    View Etherscan
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h2 class="no-allocation">
              You have no allocations in this pool.
            </h2>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Component imports.
import Ethereum from '/src/components/common/Ethereum';
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';
import Dialog from 'primevue/dialog';

// Set up the default component.
export default {
  name: 'Pool',

  data () {
    return {
      showAddress: false
    };
  },

  methods: {
    refresh () {
      window.location.reload();
    }
  },

  components: {
    Ethereum,
    Button,
    TabView,
    TabPanel,
    ProgressBar,
    Dialog,
    Badge
  },

  setup () {
    const store = useStore();

    const pool = ref();
    const ethPrice = ref(ethers.BigNumber.from(0));

    const isLoading = ref(false);
    const displayModal = ref(false);
    const displaySecondModal = ref(false);

    const amount = ref(ethers.BigNumber.from(0));
    const allocations = ref([]);
    const personalMaxAllocation = ref(ethers.BigNumber.from(0));
    const canParticipate = ref(false);


    // Parse the launchpad address and pool ID from the page's path.
    const {
      params: { address, id }
    } = useRoute();

    // Initialize the page by retrieving details about the pool.
    // TODO: optimize to retrieve data about just this one relevant pool.
    const initializePage = async function () {
      await store.dispatch('pool/getPools');
      for (let candidatePool of store.state.pool.pools) {
        if (candidatePool.contractAddress === address && candidatePool.id === parseInt(id)) {
          pool.value = candidatePool;
          break;
        }
      }

      // Retrieve all events attached to the user.
      if (store.state.ethers.canSign) {
        await store.dispatch('pool/getUserEvents', { pool: pool.value });
        allocations.value = store.state.pool.events;

        // Retrieve the personal max allocation for the user.
        await store.dispatch('pool/getUserMaxAllocation', { pool: pool.value });
        personalMaxAllocation.value = store.state.pool.userMaxAllocation;
      }

      // Determine whether or not the user may participate in the pool.
      let personalAmountAllocated = ethers.BigNumber.from(0);
      for (let i = 0; i < allocations.value.length; i++) {
        personalAmountAllocated.add(allocations.value[i].swap.args.amt);
      }
      canParticipate.value = pool.value.isWhitelist
        ? personalAmountAllocated.lt(personalMaxAllocation.value)
        : personalAmountAllocated.lt(pool.value.maxCap);

      // Retrieve the price of Ether.
      try {
        let priceResponse = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
        );
        ethPrice.value = Math.floor(priceResponse.data.ethereum.usd * 100);
      } catch (error) {
        console.error(error);
      }
    };

    const ethToUsd  = function (eth) {
      return ethers.utils.formatEther(eth.mul(ethPrice.value).div(100));
    };

    const showModal = (value) => {
      displayModal.value = value;
    };

    // Allow the user to join the pool by purchasing tokens.
    const joinPool = async function () {
      isLoading.value = true;
      await store.dispatch('pool/swap', { pool: pool.value, amount: amount.value });
      displayModal.value = false;
      isLoading.value = false;
      displaySecondModal.value = true;
    };

    // Allow the user to claim their tokens from a pool.
    const claimToken = async () => {
      isLoading.value = true;
      await store.dispatch('pool/claim', { pool: pool.value });
      isLoading.value = false;
    };

    const navigateToAddress = address => {
      window.open(`https://etherscan.io/token/${address}`);
    };

    const setMaxAllocation = () => {
      amount.value = ethers.utils.formatEther(personalMaxAllocation.value.mul(pool.value.price).div(ethers.utils.parseEther('1')));
    };

    return {
      ethers,
      formatNumber,
      initializePage,
      address,
      pool,
      saleProgress: computed(() => { return pool.value.currentRaise.mul(100).div(pool.value.totalRaise); }),
      ethToUsd,
      joinPool,
      claimToken,
      isLoading,
      showModal,
      amount,
      displayModal,
      displaySecondModal,
      allocations,
      personalMaxAllocation,
      navigateToAddress,
      canParticipate,
      setMaxAllocation
    };
  }
};
</script>

<style scoped lang="scss">
.modal-button-wrapper {
  margin-top: 10px;
}

.modal-button-wrapper > * {
  margin-right: 10px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.input-eth-label {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-bottom: 20px;
  opacity: 0.5;
  font-weight: 500;
}
.number-input {
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: transparent;
  font-size: 15px;
  font-family: 'Work Sans';
  color: rgba(0, 0, 0, 0.8);
  outline: none;
}

.number-input:focus {
  outline: none;
  border-width: 1px;
  border-color: #7112ff;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input[type='number'] {
  -moz-appearance: textfield;
}

.buttons-wrapper {
  margin-bottom: 24px;
}
.published-time {
  margin-left: 16px;
  font-weight: 500;
}
.social__link {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px 10px;
  color: #7112ff;
}
.no-allocation {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: 500;
}
.p-button-allocation {
  display: flex;
  background-color: #7112ff;
  height: 35px;
  right: 0.6em;
  bottom: 0.6em;
  padding: 12px;
  border-radius: 24px;
  color: #fff;
  &:hover {
    background-color: var(--primary-color);
    box-shadow: 0 12px 40px 0 rgb(0 0 0 / 20%);
  }
}
.p-button-new {
  position: relative;
  display: inline-flex;
  background-color: #7112ff;
  height: 40px;
  padding: 0px 15px;
  border-radius: 24px;
  color: #fff;
  &:hover {
    background-color: var(--primary-color);
    box-shadow: 0 12px 40px 0 rgb(0 0 0 / 20%);
  }
}
.max-allocation {
  margin-bottom: 16px;
}
.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}
.token-conversion {
  margin-bottom: 16px;
}
.name {
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 500;
}
.dialog-image {
  width: 54px;
  height: 54px;
  margin-bottom: 14px;
  border-radius: 50%;
}
.addressButton {
  border-radius: 10px;
  height: 10px;
  font-size: 0.7rem;
  display: inline-block;
  line-height: 0.3em;
  background-color: #7112ff;
  text-align: center;
  margin-bottom: -0.2em;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  opacity: 0.85;
}
.status {
  h5 {
    font-size: 1rem;
    font-weight: 500;
  }

  p.badge-filled {
    display: flex;
    padding: 6px 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #000;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
  }

  .p-badge-success {
    background-color: rgba(78, 194, 0, 0.2);
    color: #4ec200;
    font-weight: 500;
    position: relative;
    padding-left: 1.4rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      margin-right: 0.4rem;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 0.5rem;
      top: 0.43rem;
      background-color: #4ec200;
      animation: Pulsate 1.2s linear infinite;
    }
  }
}
.p-badge-closed {
  background-color: rgba(0, 0, 0, 0.2);
  color: #000;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  padding-left: 1.4rem;
  &:before {
    content: '';
    display: block;
    position: absolute;
    margin-right: 0.4rem;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0.5rem;
    top: 0.43rem;
    background-color: #000;
  }
}
.p-badge-filled {
  background-color: rgba(78, 194, 0, 0.2);
  color: #4ec200;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  padding-left: 1.4rem;
  &:before {
    content: '';
    display: block;
    position: absolute;
    margin-right: 0.4rem;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0.5rem;
    top: 0.43rem;
    background-color: #4ec200;
  }
}
.p-badge-danger {
  background-color: rgba(195, 1, 1, 0.2);
  color: #c30101;
  font-weight: 500;
  position: relative;
  padding-left: 1.4rem;
  font-size: 12px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    margin-right: 0.4rem;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0.5rem;
    top: 0.43rem;
    background-color: #c30101;
    animation: Pulsate 1.2s linear infinite;
  }
}

.card {
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  border-color: rgba(113, 18, 255, 0.2);
  background: #fff;

  .p-progressbar {
    margin-bottom: 0.5rem;
  }

  .progress-value {
    margin-bottom: 1rem;
    color: #4ec200;
    font-weight: 500;

    span {
      opacity: 0.5;
      color: #000;
      font-weight: 400;
    }
  }

  .rate {
    text-align: right;
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 500;
  }

  .usd-rate {
    margin-top: 0px;
    text-align: right;
    color: var(--secondary-color);
    font-size: 0.9rem;
    opacity: 0.7;
  }

  .value {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 2rem;
    color: var(--primary-color);
    font-weight: 500;

    span {
      opacity: 0.5;
      color: #000;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  h5 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 1rem;
    margin-top: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}

.p-my-12 {
  margin: 6rem 0;
}

.container {
  max-width: 85rem;
  margin: 0 auto;
}
.img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.contract {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
  word-break: break-all;
}

.button {
  border-radius: 24px;
  min-height: 40px;
  font-size: 1.15rem;
  background-color: var(--primary-color);

  transition: box-shadow 200ms ease;
  &:hover {
    background-color: var(--primary-color);
    box-shadow: 0 12px 40px 0 rgb(0 0 0 / 20%);
  }
}

.button.p-button-outlined {
  border-color: rgba(113, 18, 255, 0.2);
  background-color: #fff;
  color: var(--primary-color);
  font-weight: 400;
  text-decoration: none;
  display: inline-block;

  &:hover {
    border-color: rgba(113, 18, 255, 0.2);
    background-color: #fff;
    color: var(--primary-color);
  }
}

.footer {
  background-color: #fff;
}

.table {
  border: 1px solid;
  border-color: rgba(113, 18, 255, 0.2);
  border-radius: 24px;
  border-collapse: collapse;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);

  .tr {
    border-top: 1px solid rgba(113, 18, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      border-top: 0;
    }
  }

  .td {
    padding: 20px;
    overflow: hidden;
    max-width: 60%;
    word-break: break-all;

    &:nth-child(2) {
      text-align: right;
    }
  }

  .td-address {
    padding-right: 20px;
    text-decoration: underline;
  }

  .td-address:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .td .subtext {
    font-size: 11px;
    opacity: 0.7;
  }
}

.table-allocations {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(113, 18, 255, 0.2);
  border-radius: 24px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.table-header {
  padding: 20px;
}

.table-header.allocations {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.table-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid rgba(113, 18, 255, 0.2);
  opacity: 0.5;
  font-weight: 500;
}

.table-row.allocations {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  opacity: 1;
}

.p-button.p-button-text {
  border-style: solid;
  border-width: 2px;
  border-color: #7112ff;
  background-color: transparent;
  color: #7112ff;
  height: 40px;
  border-radius: 24px;
  margin-right: 20px;

  &:hover,
  &:enabled:hover,
  &:enabled:active {
    color: #fff;
    background: #7112ff;
    border-color: rgba(0, 0, 0, 0.2);
  }
}

.p-inputtext {
  display: block;
  width: 300px;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.p-dialog .p-dialog-header {
  display: none !important;
}

.p-dialog .p-dialog-content {
  position: relative;
  width: 400px;
  padding: 20px;
  border-radius: 24px;
  background-color: #fff;
}

.tabview.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #000;
}

.tabview.p-tabview {
  .p-tabview-nav {
    flex-wrap: nowrap;
  }
  .p-tabview-nav li .p-tabview-nav-link {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 1.5rem 1.5rem;

    &:focus,
    &:hover {
      background: transparent;
    }
  }
}

.tabview.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background: var(--primary-color);
  height: 4px;
}
</style>
