"use strict";

// Import our module information.
import actions from "./actions";
import mutations from "./mutations";

// Specify state for this module.
const state = {
  // Is the page guaranteed hidden based on the browser Page Visibility API.
  hidden: false,

  // Is the page probably hidden based on our user activity detection?
  probablyHidden: false,

  // How long has it been since we detected any user activity?
  timeWithoutAction: 0
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
