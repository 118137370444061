<template>
  <div class="wrapper">
    <TheHeader />
    <router-view />
    <Footer class="p-col-12" />
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';

// Component imports.
import Footer from '/src/components/Footer.vue';
import TheHeader from '/src/components/TheHeader';

// Set up the default application component.
export default {
  name: 'SuperFarm',
  components: {
    TheHeader,
    Footer
  },

  // Retrieve a new copy of the alert module's state.
  computed: {
    ...mapState({
      alert: state => Object.assign({}, state.alert),
      ethereum: state => state.ethers,
      visibility: state => Object.assign({}, state.visibility)
    })
  },

  // Register the listener for focus detection changes.
  created() {
    this.setup({ threshold: 10 * 60 * 1000 });
  },

  // Support application-wide suspension of Ethers service polling when the
  // application loses focus. This saves on default provider requests.
  methods: {
    ...mapActions('alert', ['clear', 'warning']),
    ...mapActions('visibility', ['setup', 'cleanup']),
    ...mapActions('ethers', ['pause', 'unpause'])
  },

  // Remove the change detection event listeners upon application cleanup.
  beforeUnmount() {
    this.cleanup();
  },

  // Watch the alert module's state for any toast notifications to show.
  watch: {
    alert: {
      deep: true,
      handler: function(newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let messageType = newAlert.type;
        let duration = newAlert.duration;
        if (message && messageType) {
          this.$toast.show(message, {
            type: messageType,
            position: 'bottom-left',
            duration: duration,
            max: 3
          });
          this.lastMessage = message;
        } else {
          this.$toast.clear();
        }
      }
    },

    // Watch the visibility module's state for reacting to page visibility
    // updates.
    visibility: {
      deep: true,
      handler: async function(newVisibility) {
        let hidden = newVisibility.hidden;
        let probablyHidden = newVisibility.probablyHidden;
        if (hidden || probablyHidden) {
          await this.pause();
        }
        if (probablyHidden) {
          await this.warning({
            message: `It seems like you've been inactive for a while. Are you still there?`,
            duration: false
          });
        }
        if (!hidden && !probablyHidden) {
          await this.unpause();
          await this.clear();
        }
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Work Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  font-size: 87.5%;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

:root {
  --font-family: 'Work Sans', Helvetica, Arial, sans-serif;
  --primary-color: #7112ff;
}

.p-component {
  font-family: var(--font-family);
}

.p-dialog {
  box-shadow: none;
}

html {
  font-size: 87.5%;
  height: 100%;
  background: #fff;
}

body {
  margin: 0;
  min-height: 100%;
  display: flex;
  background-color: rgba(113, 18, 255, 0.03);
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}
.p-progressbar {
  border-radius: 24px;
  background: rgba(78, 194, 0, 0.2);
  height: 8px;
}
.p-progressbar .p-progressbar-value {
  background: #4ec200;
}

ul.ul {
  list-style: none;
  text-align: left;
  margin: 0;
  margin-top: 16px;
  padding: 0;
}

ul.ul li {
  padding: 0;
  margin-bottom: 8px;
}

p {
  font-size: 1.15rem;
}

a.a {
  color: #000;
  text-decoration: none;
  font-family: var(--font-family);
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
}
</style>
