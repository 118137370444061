<template>
  <Dashboard />
</template>

<script>
'use strict';

// Component imports.
import Dashboard from '/src/components/Dashboard.vue';

// Define the default component.
export default {
  name: 'Home',
  components: {
    Dashboard
  },

  // Set up the default component.
  setup () {
    document.title = 'SuperStarter Project Launchpad';
  }
};
</script>
