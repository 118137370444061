"use strict";

// Specified actions for mutating the alert state.
export default {
  // Display a success-themed alert.
  success({ commit }, { message, duration }) {
    commit("success", { message, duration });
  },

  // Display an information-themed alert.
  info({ commit }, { message, duration }) {
    commit("info", { message, duration });
  },

  // Display a warning-themed alert.
  warning({ commit }, { message, duration }) {
    commit("warning", { message, duration });
  },

  // Display an error-themed alert.
  error({ commit }, { message, duration }) {
    commit("error", { message, duration });
  },

  // Display an alert with the default theme.
  default({ commit }, { message, duration }) {
    commit("default", { message, duration });
  },

  // Clear all alerts.
  clear({ commit }) {
    commit("clear");
  },

  // TODO
  showWalletModal({ commit }) {
    commit("showWalletModal");
  },
  hideWalletModal({ commit }) {
    commit("hideWalletModal");
  }
};
