"use strict";

// Construct a cache helper which can consume a promise and caches its result.
export class CacheHelper {
  constructor() {
    this.isWorking = false;
    this.doneWorking = false;
    this.waiters = [];
    this.onBeginWorking = null;
    this.onError = null;
    this.onFinishWorking = null;
    this.response = null;
  }

  // Multiple pending calls to this helper function will only call the promise
  // once unless cache-busting is specified.
  async cache(promiseGetter, bust) {
    if (!this.doneWorking && !this.isWorking) {
      this.isWorking = true;
      if (this.onBeginWorking) {
        await this.onBeginWorking();
      }
      try {
        this.response = await promiseGetter();
      } catch (error) {
        if (this.onError) {
          await this.onError(error);
        } else {
          throw error;
        }
      }
      if (this.onFinishWorking) {
        await this.onFinishWorking(this.response);
      }
      this.doneWorking = true;

      // Process any registered callbacks.
      for (let waiter of this.waiters) {
        waiter.responseAvailable(this.response);
      }
      this.waiters = [];
      return this.response;

      // If another request has been made to the cache while it is working,
      // register a callback to resolve with the cached value once available.
    } else if (!this.doneWorking && this.isWorking) {
      let waiterPromise = new Promise(resolve => {
        this.waiters.push({
          resolver: resolve,
          responseAvailable: function (response) {
            this.resolver(response);
          }
        });
      });
      return waiterPromise;

      // If the value is available, return it from the cache.
    } else if (this.doneWorking) {
      return this.response;
    }
  }
}
