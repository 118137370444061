"use strict";

// Specified state transitions per particular actions.
export default {
  success(state, { message, duration }) {
    state.message = message;
    state.type = "success";
    state.duration = duration;
  },
  info(state, { message, duration }) {
    state.message = message;
    state.type = "info";
    state.duration = duration;
  },
  warning(state, { message, duration }) {
    state.message = message;
    state.type = "warning";
    state.duration = duration;
  },
  error(state, { message, duration }) {
    state.message = message;
    state.type = "error";
    state.duration = duration;
  },
  default(state, { message, duration }) {
    state.message = message;
    state.type = "default";
    state.duration = duration;
  },

  // Tell the app to clear all alerts.
  clear(state) {
    state.type = null;
    state.message = null;
  },

  // TODO
  showWalletModal(state) {
    state.showWalletModal = true;
  },
  hideWalletModal(state) {
    state.showWalletModal = false;
  }
};
