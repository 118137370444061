"use strict";

// Import our module information.
import actions from "./actions";
import mutations from "./mutations";

// Prepare state.
const state = {
  message: null,
  type: null,
  duration: 10000,

  // TODO: fix this alert VueX storage hijacking.
  showWalletModal: false
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
