<template>
  <Ethereum :callback="initialize" />

  <!-- Render live pools. -->
  <div class="p-col-12 p-px-3 p-px-sm-6 container p-mb-6">
    <div v-if="pools.length > 0">
      <h2 class="p-text-left p-mt-6">Featured Pools</h2>
      <div class="p-grid">
        <div
          class="p-col-12 p-lg-4 p-md-6 p-sm-12 p-xl-4"
          v-for="(pool, index) in pools"
          :key="index"
        >
          <PoolCard :pool="pool"/>
        </div>
      </div>
    </div>

    <!-- Render upcoming pools. -->
    <div v-if="upcomingPools.length > 0">
      <h2 class="p-text-left p-mt-6">Upcoming Pools</h2>
      <div class="p-grid">
        <div
          class="p-col-12 p-lg-4 p-md-6 p-sm-12 p-xl-4"
          v-for="(upcomingPool, index) in upcomingPools"
          :key="index"
        >
          <PoolCard
            :pool="upcomingPool"
          />
        </div>
      </div>
    </div>

    <!-- Render closed pools. -->
    <div v-if="closedPools.length > 0">
      <h2 class="p-text-left p-mt-6">Closed Pools</h2>
      <div class="p-grid">
        <div
          class="p-col-12 p-lg-4 p-md-6 p-sm-12 p-xl-4"
          v-for="(closedPool, index) in closedPools"
          :key="index"
        >
          <PoolCard
            :pool="closedPool"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ref } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import Ethereum from '../components/common/Ethereum';
import PoolCard from '/src/components/PoolCard.vue';

// Define the default component.
export default {
  name: 'Dashboard',
  components: {
    Ethereum,
    PoolCard
  },

  // Export the default component.
  setup () {
    const store = useStore();
    const pools = ref([]);
    const upcomingPools = ref([]);
    const closedPools = ref([]);

    const initialize = async () => {
      await updatePage();
      await startPolling();
    };

    const updatePage = async () => {
      await store.dispatch('pool/getPools');
      pools.value = [];
      upcomingPools.value = [];
      closedPools.value = [];
      for (let pool of store.state.pool.pools.reverse()) {
        if (pool.enabled) {
          pools.value.push(pool);
        } else if (!pool.finished) {
          upcomingPools.value.push(pool);
        } else {
          closedPools.value.push(pool);
        }
      }
    };

    let refreshInterval;
    const startPolling = async () => {
      refreshInterval = setInterval(updatePage, 10000);
    };

    const stopPolling = async () => {
      clearInterval(refreshInterval);
    };

    // Return this component's data.
    return {
      initialize,
      pools,
      upcomingPools,
      closedPools
    };
  }
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
</style>
