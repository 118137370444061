<template>
  <div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';
import { useTask } from 'vue-concurrency';
import initializeConfig from '/src/initialize-config';
import store from '/src/store';

// Set up the default component.
export default {
  props: {
    callback: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers,
    })
  },

  created() {
    let ethereumReference = this.ethereum;
    this.getLaunchpadItemsTask = useTask(function*(signal, callback) {
      this.config = yield initializeConfig();
      if (!ethereumReference.provider) {
        yield store.dispatch('ethers/initialize', async () => {
          await callback();
        });
      } else {
        yield callback();
      }
    });
    this.getLaunchpadItemsTask.perform(this.callback);
  }
}
</script>
