// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../views/Home.vue';
import Pool from '../views/Pool.vue';

// Create routes.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pool/:address/:id',
    name: 'Pool',
    component: Pool
  }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
