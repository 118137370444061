'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethers } from 'ethers';
import { ethersService } from './ethers.service';

// TODO: all of these fields should be dynamically read from a backend API.
// Retrieve off-chain contract and pool-specific constants.
import {
  tokensAvailable, // TODO: this specific function must move to on-chain data in v2.
  currentRaise,
	maxAllocation, // TODO: this one too.
  softCap, // TODO: this one too.

	// Hard-coded data that will eventually live in Dynamo.
	name,
	image,
	distribution,
	descriptions,
	descriptionImage,
	twitterLink,
	telegramLink,
	telegramSecondLink,
	whitePaperLink
} from '../constants/pool-information';

// Make a best-pass effort at immediate configuration initialization.
let config;
(async () => {
	config = await initializeConfig();
})();

// Retrieve all pools that the SuperStarter is aware of.
const getPools = async function () {
  if (!config) {
    config = await initializeConfig();
  }

	// Use the user's provider to retrieve all SuperPad pools.
  let provider = await ethersService.getProvider();
	const pools = [];

	// Access all known SuperPad contracts.
	let network = await provider.getNetwork();
	let networkId = ethers.utils.hexValue(network.chainId);
	let superPadAddresses = config.superPadAddresses[networkId];
	for (let i = 0; i < superPadAddresses.length; i++) {
		let contractAddress = superPadAddresses[i];
		let superPadContract = new ethers.Contract(contractAddress, config.superPadABI, provider);

		// Retrieve all pools for this contract.
	  let poolsLength = await superPadContract.poolsLength();
	  for (let j = 0; j < poolsLength; j++) {
	    const poolOutput = await superPadContract.pools(j);

			// Dynamically retrieve information about the pool's token which users purchase with.
			let tokenWhichPurchasesSymbol;
			let tokenWhichPurchasesDecimals;
			if (poolOutput.swapToken === ethers.constants.AddressZero) {
				tokenWhichPurchasesSymbol = 'ETH';
				tokenWhichPurchasesDecimals = ethers.BigNumber.from(18);
			} else {
				let tokenWhichPurchasesContract = await new ethers.Contract(poolOutput.swapToken, config.tokenABI, provider);
				tokenWhichPurchasesSymbol = await tokenWhichPurchasesContract.symbol();
				tokenWhichPurchasesDecimals = await tokenWhichPurchasesContract.decimals();
			}

			// Dynamically retrieve information about the pool's token which is being sold.
			let tokenForSaleContract = await new ethers.Contract(poolOutput.token, config.tokenABI, provider);
			let tokenForSaleSymbol = await tokenForSaleContract.symbol();
			let tokenForSaleDecimals = await tokenForSaleContract.decimals();
			let tokenForSaleTotalSupply = await tokenForSaleContract.totalSupply();

			// Calculate the sale price of the token for sale in tokens which users purchase with.
			// TODO: fix this nonsense by moving the price scaling inside the contract.
      let etherEstimate = (100000000.0 / poolOutput.price.toNumber()).toFixed(9);
			let salePrice = ethers.utils.parseEther(`${etherEstimate}`);

			// If the pool is not whitelisted, then the tokens available is simply the pool cap.
			let poolTokensAvailable;
			if (!poolOutput.isWhiteList) {
				poolTokensAvailable = poolOutput.cap;

			// If a whitelist exists, the total tokens available is the lesser of
			// - the full allocation awared by the whitelist or
			// - the pool cap.
			} else {
				poolTokensAvailable = tokensAvailable(networkId, contractAddress, j);

				// TODO: version two of the smart contract will require discoverability for total whitelist sums.
				// let whitelistSum = ...;
			}

      // Calculate the pool's total raise from the tokens being sold.
      let poolTotalRaise = poolTokensAvailable.mul(salePrice).div(ethers.utils.parseEther('1'));

			// If the pool is not whitelisted, then the maximum allocation is the number of
			// tokens which purchase that results in reaching the pool cap of tokens for sale.
			// TODO: this number will require better on-chain discoverability of whitelist state,
			// as well as some intelligent token ratio conversions.
			let poolMaxAllocation;
			if (!poolOutput.isWhiteList) {
				poolMaxAllocation = maxAllocation(networkId, contractAddress, j);

			// If a whitelist exists, then the maximum allocation is based off of the smallest
			// member of the whitelist's maximum allocation.
			} else {
				poolMaxAllocation = maxAllocation(networkId, contractAddress, j).mul(1);
			}

      // Find the current amount raised by summing the Swap events.
      const swapFilter = superPadContract.filters.Swap();
      const events = await superPadContract.queryFilter(swapFilter);
      let poolCurrentRaise = ethers.BigNumber.from(0);
      for (let event of events) {
        if (event.args.id.eq(ethers.BigNumber.from(j))) {
          poolCurrentRaise = poolCurrentRaise.add(event.args.amount);
        }
      }

      // Apply a manual override to the current amount raised.
      let manualCurrentRaise = currentRaise(networkId, contractAddress, j);
      if (manualCurrentRaise) {
        poolCurrentRaise = manualCurrentRaise;
      }

      // Retrieve the pool's soft cap.
      // TODO: right now this is completely fake and requires further contract support.
      let poolSoftCap = softCap(networkId, contractAddress, j);

			// Construct the pool output.
			const pool = {
				id: j,
				contractAddress: contractAddress,
				cap: poolOutput.cap,
				creator: poolOutput.creator,
				enabled: poolOutput.enabled,
				finished: poolOutput.finished,
				isWhitelist: poolOutput.isWhiteList,
				maxCap: poolOutput.maxCap,
				onlyHolder: poolOutput.onlyHolder,
				price: salePrice,
				tokenWhichPurchases: {
					address: poolOutput.swapToken,
					symbol: tokenWhichPurchasesSymbol,
					decimals: tokenWhichPurchasesDecimals
				},
				tokenForSale: {
					address: poolOutput.token,
					symbol: tokenForSaleSymbol,
					decimals: tokenForSaleDecimals,
					totalSupply: tokenForSaleTotalSupply
				},
        tokensAvailable: poolTokensAvailable,
        currentRaise: poolCurrentRaise,
        softCap: poolSoftCap,
				totalRaise: poolTotalRaise,
				minAllocation: ethers.BigNumber.from(0),
				maxAllocation: poolMaxAllocation,

				// Front-end styling information.
				name: name(networkId, contractAddress, j),
				image: image(networkId, contractAddress, j),
				distribution: distribution(networkId, contractAddress, j),
				descriptions: descriptions(networkId, contractAddress, j),
				descriptionImage: descriptionImage(networkId, contractAddress, j),
				twitterLink: twitterLink(networkId, contractAddress, j),
				telegramLink: telegramLink(networkId, contractAddress, j),
				telegramSecondLink: telegramSecondLink(networkId, contractAddress, j),
				whitePaperLink: whitePaperLink(networkId, contractAddress, j)
			};

			// Store the pool.
	    pools.push(pool);
	  }
	}
	return pools;
}

const claim = async (pool) => {
  if (!config) {
    config = await initializeConfig();
  }

  // Use the provider to get the signer and contract address
  const provider = await ethersService.getProvider();
  const signer = await provider.getSigner();
  const network = await provider.getNetwork();
	const networkId = ethers.utils.hexValue(network.chainId);
	const superPadAddress = pool.contractAddress;
  const superPadContract = new ethers.Contract(superPadAddress, config.superPadABI, signer);

  // Claim the tokens for the given pool
  const claimTx = await superPadContract.claim(pool.id);
  await claimTx.wait()
};

const swap = async (pool, amount) => {
  if (!config) {
    config = await initializeConfig();
  }

  const provider = await ethersService.getProvider();
  const signer = await provider.getSigner();
  const address = await signer.getAddress();
  const network = await provider.getNetwork();
	const networkId = ethers.utils.hexValue(network.chainId);
	const superPadAddress = pool.contractAddress;
  const superPadContract = new ethers.Contract(superPadAddress, config.superPadABI, signer);

  const purchaseAmount = ethers.utils.parseEther(amount);

  // If the token is not Ethereum, check for approval.
  const tokenAddress = pool.tokenWhichPurchases.address;
  let swapTransaction;
  if (tokenAddress !== ethers.constants.AddressZero) {
    let tokenWhichPurchasesContract = await new ethers.Contract(tokenAddress, config.tokenABI, signer);
    let allowance = await tokenWhichPurchasesContract.allowance(address, superPadAddress);
    if (!allowance.gt(0)) {
      let approvalTransaction = await tokenWhichPurchasesContract.approve(superPadAddress, ethers.constants.MaxUint256);
      await approvalTransaction.wait();
    }
    swapTransaction = await superPadContract.swap(pool.id, purchaseAmount);

  // The purchase is being performed with Ether. Send Ether.
  } else {
    swapTransaction = await superPadContract.swap(pool.id, purchaseAmount, { value: purchaseAmount });
  }

  // Perform swap.
  await swapTransaction.wait();
};

const getUserAllocations = async (pool) => {
  if (!config) {
    config = await initializeConfig();
  }
  const provider = await ethersService.getProvider();
  const signer = await provider.getSigner();
  const address = await signer.getAddress();
  const network = await provider.getNetwork();
	const networkId = ethers.utils.hexValue(network.chainId);
	const superPadAddress = pool.contractAddress;
  const superPadContract = new ethers.Contract(superPadAddress, config.superPadABI, signer);

  // Parse relevant events to produce the array of allocation information.
  let allocations = [];

  // Find all Swap events.
  const swapFilter = superPadContract.filters.Swap();
  const swapEvents = await superPadContract.queryFilter(swapFilter);

  // Find all Claim events.
  const claimFilter = superPadContract.filters.Claim();
  const claimEvents = await superPadContract.queryFilter(claimFilter);

  // Find all Swap events for the user, along with any corresponding Claim events.
  let poolId = ethers.BigNumber.from(pool.id);
  for (let swapEvent of swapEvents) {
    if (swapEvent.args.id.eq(poolId) && swapEvent.args.sender === address) {
      let claim;

      // Check for a corresponding Claim event.
      for (let claimEvent of claimEvents) {
        if (claimEvent.args.id.eq(poolId) && claimEvent.args.claimer === address) {
          claim = claimEvent;
          break;
        }
      }

      // Store the object we found.
      allocations.push({ swap: swapEvent, claim: claim });
    }
  }
  return allocations;
};

const getUserMaxAllocation = async (pool) => {
  if (!config) {
    config = await initializeConfig();
  }
  const provider = await ethersService.getProvider();
  const signer = await provider.getSigner();
  const address = await signer.getAddress();
  const network = await provider.getNetwork();
	const networkId = ethers.utils.hexValue(network.chainId);
	const superPadAddress = pool.contractAddress;
  const superPadContract = new ethers.Contract(superPadAddress, config.superPadABI, signer);

  // TODO: this extra retrieval of the pool should not be necessary.
  let fetchPool = await superPadContract.pools(pool.id);
  if (fetchPool.isWhiteList) {
    return await superPadContract.whiteList(pool.id, address);
  } else {
    return fetchPool.maxCap;
  }
}

// Export functionality from our service.
export const poolService = {
  getPools,
  claim,
  swap,
  getUserAllocations,
  getUserMaxAllocation
};
