'use strict';

// Imports.
import { poolService } from '../../services/pool.service';

// Get all pools known to the SuperStarter.
export default {
  async getPools ({ dispatch, commit }) {
    try {
      let pools = await poolService.getPools();
      commit('updatePools', pools);
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getUserEvents ({ dispatch, commit }, { pool }) {
    try {
      let events = await poolService.getUserAllocations(pool);
      commit('updateUserEvents', events);
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getUserMaxAllocation ({ dispatch, commit }, { pool }) {
    try {
      let maxAllocation = await poolService.getUserMaxAllocation(pool);
      commit('updateUserMaxAllocation', maxAllocation);
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async claim ({ dispatch }, { pool }) {
    try {
      await poolService.claim(pool);
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async swap ({dispatch}, { pool, amount }) {
    try {
      await poolService.swap(pool, amount);
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
