'use strict';

// Component imports.
import { createApp } from 'vue';
import Toaster from '@meforma/vue-toaster';
import PrimeVue from 'primevue/config';

// Style imports.
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './assets/style/main.scss';
import 'primeflex/primeflex.min.css';
import 'primevue/resources/themes/md-light-deeppurple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// Application imports.
import App from './App.vue';
import router from './router';
import store from './store';

// Create our application with required dependencies.
createApp(App)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(Toaster)
  .mount('#app');
