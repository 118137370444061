"use strict";

// Imports.
import { visibilityService } from "../../services";

// Specify actions that can be performed by this module.
export default {
  // Set up the visibility service with a particular activity threshold.
  async setup({ dispatch }, { threshold }) {
    await visibilityService.setup(dispatch, threshold);
  },

  // Clean up the visibility service by removing event listeners.
  async cleanup({}) {
    await visibilityService.cleanup();
  },

  // Update current page visibility pepr the visibility service.
  async update({ commit }, { hidden, probablyHidden, timeWithoutAction }) {
    commit("update", { hidden, probablyHidden, timeWithoutAction });
  }
};
