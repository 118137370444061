'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = () => ({

  // An array of all pools known to the SuperStarter.
  pools: [],

  // An array of all user events.
  events: [],

  // The maximum allocation a whitelisted user is entitled to.
  userMaxAllocation: 0
});

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
