'use strict';

// Export state mutation functions.
export default {
  updatePools (state, pools) {
    state.pools = pools;
  },
  updateUserEvents (state, events) {
    state.events = events;
  },
  updateUserMaxAllocation (state, maxAllocation) {
    state.userMaxAllocation = maxAllocation;
  }
}
