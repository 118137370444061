"use strict";

// Imports.
import initializeConfig from "../initialize-config";
import { PlayFab, PlayFabClient } from "playfab-sdk";
import { promisify } from "util";

// Wrap some select PlayFab methods into asynchronous promises.
let aRegister = promisify(PlayFabClient.RegisterPlayFabUser).bind(
  PlayFabClient
);
let aLogin = promisify(PlayFabClient.LoginWithEmailAddress).bind(PlayFabClient);

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// Issue the request to register the user on PlayFab.
const register = function (user) {
  PlayFab.settings.titleId = config.titleId;
  return aRegister({
    TitleId: PlayFab.settings.titleId,
    RequireBothUsernameAndEmail: false,
    Email: user.email,
    Password: user.password
  });
};

// Issue the request to log the user in on PlayFab.
const login = function (email, password) {
  PlayFab.settings.titleId = config.titleId;
  return aLogin({
    TitleId: PlayFab.settings.titleId,
    Email: email,
    Password: password
  });
};

// Log the user out by removing their data from local storage.
const logout = function () {
  localStorage.removeItem("user");
};

// Export the user service functions.
export const userService = {
  register,
  login,
  logout
};
