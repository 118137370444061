'use strict';

// Imports.
import { ethers } from 'ethers';

// Define particular hard-coded information for every known pool.
const KNOWN_POOLS = {
  '0x1': {
    '0x767914595a652b885c08a9ab378585af5d5f25c2': [
      {
        tokensAvailable: ethers.utils.parseEther('111200'),
        currentRaise: ethers.utils.parseEther('13.345'),
        maxAllocation: ethers.utils.parseEther('0.096'),
        softCap: ethers.utils.parseEther('10.00875'),
        name: 'Coldstack - Guaranteed',
        image: '/images/coldstack-small.svg',
        distribution: 'TBA',
        descriptions: [
          'ColdStack is a Decentralized Cloud Aggregator: it is the world’s first service that aggregates Decentralized Data Storages such as Filecoin. It is the fastest, simplest, and easiest way to unleash the power of Decentralized Clouds for projects within the crypto space and beyond. By optimizing storage solutions with its AI-based pipeline, ColdStack provides users with the most cost-efficient and secure way to store their files. ColdStack approach is transparent, immutable, and cryptographically verifiable. The platform provides simple and seamless integration of all the Decentralized Data Storages in one platform. There will also be no need for data migration needed to switch between various Decentralized Clouds.',
          'ColdStack features a unified Amazon S3 compatible API which allows users to easily access decentralized storage solutions. By utilizing the $CLS token, users gain access to nearly any decentralized storage, and are no longer required to research the particular strengths of each service in addition to comparing costs. With our implementation of Amazon S3 API compatibility, we also allow individuals to access these services through an interface they are already familiar with, further streamlining a user’s experience. Users can also access a wide range of file permissions and curate their experience based on their data storage needs. The unified API will create easy data storage and retrieval that will be possible from anywhere and anytime.',
          'NFTs also add another layer of complexity and fragmentation to decentralized storages, as these must also be stored on their respective networks and marketplaces. The platform has NFT support allowing users to tokneize any uploaded file in ERC-721 and ERC-1155 standards. As it currently stands, the ColdStack platform will be the only way a user can store bulk data with Filecoin, 4k photos with Storj, webpages with Arweave, and list NFTs with OpenSea and Rarible, all with one token.'
        ],
        descriptionImage: '/images/coldstack-logo.svg',
        twitterLink: 'https://twitter.com/coldstack_io',
        telegramLink: 'https://t.me/coldstackio',
        telegramSecondLink: 'https://t.me/coldstack_ann',
        whitePaperLink: 'https://coldstack.io/files/ColdStackLitePaper.pdf'
      },
      {
        tokensAvailable: ethers.utils.parseEther('388800'),
        maxAllocation: ethers.utils.parseEther('0.096'),
        softCap: ethers.utils.parseEther('34.992'),
        name: 'Coldstack - SUPER FCFS',
        image: '/images/coldstack-small.svg',
        distribution: 'TBA',
        descriptions: [
          'ColdStack is a Decentralized Cloud Aggregator: it is the world’s first service that aggregates Decentralized Data Storages such as Filecoin. It is the fastest, simplest, and easiest way to unleash the power of Decentralized Clouds for projects within the crypto space and beyond. By optimizing storage solutions with its AI-based pipeline, ColdStack provides users with the most cost-efficient and secure way to store their files. ColdStack approach is transparent, immutable, and cryptographically verifiable. The platform provides simple and seamless integration of all the Decentralized Data Storages in one platform. There will also be no need for data migration needed to switch between various Decentralized Clouds.',
          'ColdStack features a unified Amazon S3 compatible API which allows users to easily access decentralized storage solutions. By utilizing the $CLS token, users gain access to nearly any decentralized storage, and are no longer required to research the particular strengths of each service in addition to comparing costs. With our implementation of Amazon S3 API compatibility, we also allow individuals to access these services through an interface they are already familiar with, further streamlining a user’s experience. Users can also access a wide range of file permissions and curate their experience based on their data storage needs. The unified API will create easy data storage and retrieval that will be possible from anywhere and anytime.',
          'NFTs also add another layer of complexity and fragmentation to decentralized storages, as these must also be stored on their respective networks and marketplaces. The platform has NFT support allowing users to tokneize any uploaded file in ERC-721 and ERC-1155 standards. As it currently stands, the ColdStack platform will be the only way a user can store bulk data with Filecoin, 4k photos with Storj, webpages with Arweave, and list NFTs with OpenSea and Rarible, all with one token.'
        ],
        descriptionImage: '/images/coldstack-logo.svg',
        twitterLink: 'https://twitter.com/coldstack_io',
        telegramLink: 'https://t.me/coldstackio',
        telegramSecondLink: 'https://t.me/coldstack_ann',
        whitePaperLink: 'https://coldstack.io/files/ColdStackLitePaper.pdf'
      }
    ],
    '0xb0c550af0E0F087aC48Bf647de00B52C981bCd0B': [
      {
        tokensAvailable: ethers.utils.parseEther('600000'),
        maxAllocation: ethers.utils.parseEther('0.09087'),
        softCap: ethers.utils.parseEther('13.0855'),
        name: 'Don-key - Guaranteed',
        image: '/images/don-key-crypto.png',
        distribution: 'TBA',
        descriptions: [
          'Yield farming is the craze that’s taken the DeFi world by storm. Over the past 12 months, the number and complexity of yield farms has soared, as have the number of blockchains that now support liquidity mining. Farming is not for the faint-hearted or tech-averse however: it’s a hazardous pursuit that calls for negotiating constantly fluctuating APYs, mitigating impermanent loss, enduring smart contract risk, and frequently entering and exiting positions.',
          'On paper, yield farming sounds like a simple way to earn a passive income from your crypto holdings. In practice, it’s a full-time job with a steep learning curve, executed in a perilous environment where a single mistake can prove costly. But enough about the downside to yield farming: Don-key is all about maximizing the upside by making it easier for humble investors and farmers to generate consistent yield, whatever their ability level.',
          'This will be achieved by bringing liquidity providers and yield farmers together on the Don-key.finance platform, where gamified social trading strategies will incentivize participants to work together and reap the spoils. Don-key will do for yield farming what social (or “copy”) trading has done for cryptocurrency trading.'
        ],
        descriptionImage: '/images/don-key-crypto-small.png',
        twitterLink: 'https://twitter.com/Don_key_finance',
        telegramLink: 'https://t.me/don_key_finance',
        telegramSecondLink: 'https://t.me/don_key_finance_ann',
        whitePaperLink: 'https://don-key.finance/litepaper.pdf'
      },
      {
        tokensAvailable: ethers.utils.parseEther('900000'),
        maxAllocation: ethers.utils.parseEther('0.09087'),
        softCap: ethers.utils.parseEther('19.628'),
        name: 'Don-key - FCFS',
        image: '/images/don-key-crypto.png',
        distribution: 'TBA',
        descriptions: [
          'Yield farming is the craze that’s taken the DeFi world by storm. Over the past 12 months, the number and complexity of yield farms has soared, as have the number of blockchains that now support liquidity mining. Farming is not for the faint-hearted or tech-averse however: it’s a hazardous pursuit that calls for negotiating constantly fluctuating APYs, mitigating impermanent loss, enduring smart contract risk, and frequently entering and exiting positions.',
          'On paper, yield farming sounds like a simple way to earn a passive income from your crypto holdings. In practice, it’s a full-time job with a steep learning curve, executed in a perilous environment where a single mistake can prove costly. But enough about the downside to yield farming: Don-key is all about maximizing the upside by making it easier for humble investors and farmers to generate consistent yield, whatever their ability level.',
          'This will be achieved by bringing liquidity providers and yield farmers together on the Don-key.finance platform, where gamified social trading strategies will incentivize participants to work together and reap the spoils. Don-key will do for yield farming what social (or “copy”) trading has done for cryptocurrency trading.'
        ],
        descriptionImage: '/images/don-key-crypto-small.png',
        twitterLink: 'https://twitter.com/Don_key_finance',
        telegramLink: 'https://t.me/don_key_finance',
        telegramSecondLink: 'https://t.me/don_key_finance_ann',
        whitePaperLink: 'https://don-key.finance/litepaper.pdf'
      }
    ],
    '0x8F83102B801ff2c376B13eE86da8Bf77123BB025': [
      {
        tokensAvailable: ethers.utils.parseEther('896000'),
        maxAllocation: ethers.utils.parseEther('0.196'),
        softCap: ethers.utils.parseEther('32.928'),
        name: 'Virtue Poker - Guaranteed',
        image: '/images/virtue-poker-small.png',
        distribution: 'TBA',
        descriptions: [
          'Conceived in 2016, Virtue Poker is a ConsenSys incubated, and Pantera backed company that uses the unique features of blockchain technology in conjunction with P2P networking to provide a safe, honest environment to play online poker.',
          'Player funds are never held by Virtue Poker; instead game buy-ins are held in escrow by Ethereum "smart contracts"​ while they play, and payouts occur in 30 seconds or less.',
          'Virtue\'s Poker\'s distributed peer-to-peer shuffling algorithm provides game security, since the deck never resides on a centralized server and is the first and only blockchain based poker platform licensed by the Malta Gaming Authority.'
        ],
        descriptionImage: '/images/virtue-poker.png',

        // TODO.
        twitterLink: 'https://twitter.com/virtue_poker',
        telegramLink: 'https://t.me/virtue_poker',
        telegramSecondLink: 'https://t.me/virtuepokerANN',
        whitePaperLink: 'https://virtue.poker/'
      },
      {
        tokensAvailable: ethers.utils.parseEther('1104000'),
        maxAllocation: ethers.utils.parseEther('0.196'),
        softCap: ethers.utils.parseEther('40.572'),
        name: 'Virtue Poker - FCFS',
        image: '/images/virtue-poker-small.png',
        distribution: 'TBA',
        descriptions: [
          'Conceived in 2016, Virtue Poker is a ConsenSys incubated, and Pantera backed company that uses the unique features of blockchain technology in conjunction with P2P networking to provide a safe, honest environment to play online poker.',
          'Player funds are never held by Virtue Poker; instead game buy-ins are held in escrow by Ethereum "smart contracts"​ while they play, and payouts occur in 30 seconds or less.',
          'Virtue\'s Poker\'s distributed peer-to-peer shuffling algorithm provides game security, since the deck never resides on a centralized server and is the first and only blockchain based poker platform licensed by the Malta Gaming Authority.'
        ],
        descriptionImage: '/images/virtue-poker.png',

        // TODO.
        twitterLink: 'https://twitter.com/virtue_poker',
        telegramLink: 'https://t.me/virtue_poker',
        telegramSecondLink: 'https://t.me/virtuepokerANN',
        whitePaperLink: 'https://virtue.poker/'
      }
    ],
    '0xf284142bC8D5e8E1e690776E96fD98705f3d49a0': [
      {
        tokensAvailable: ethers.utils.parseEther('750000'),
        maxAllocation: ethers.utils.parseEther('0.108'),
        softCap: ethers.utils.parseEther('20.25'),
        name: 'Alpha Impact - Guaranteed',
        image: '/images/alpha-impact-small.png',
        distribution: 'TBA',
        descriptions: [
          'Alpha Impact: A Non-Custodial Social Trading and DeFi Platform. While many emerging projects are just copying existing DeFi protocols running on Ethereum and bringing them to other chains like BSC, Polygon, and soon Cardano, Alpha Impact is a true innovator in the DeFi space.',
          'The project is building a full suite of DeFi applications with the vision to democratize investing and provide tools for everyone — both small fish and whales. Alpha Impact will cater to crypto investors looking for the best strategies and also traders seeking to monetize their skills.',
          'Users will be able to use several products, including spot, leverage, options, and various DeFi strategies. Thanks to Alpha Impact’s proprietary non-custodial copy-trade technology, users will be able to follow the strategies of other successful traders — while always preserving ownership of their funds.'
        ],
        descriptionImage: '/images/alpha-impact.png',

        // TODO.
        twitterLink: 'https://twitter.com/alphaimpact_fi',
        telegramLink: 'https://t.me/alphaimpact_fi',
        telegramSecondLink: 'https://t.me/alphaimpact_fi_ANN',
        whitePaperLink: 'https://docsend.com/view/p4gnc5tzci4maegr'
      },
      {
        tokensAvailable: ethers.utils.parseEther('1248000'),
        maxAllocation: ethers.utils.parseEther('0.108'),
        softCap: ethers.utils.parseEther('33.696'),
        name: 'Alpha Impact - FCFS',
        image: '/images/alpha-impact-small.png',
        distribution: 'TBA',
        descriptions: [
          'Alpha Impact: A Non-Custodial Social Trading and DeFi Platform. While many emerging projects are just copying existing DeFi protocols running on Ethereum and bringing them to other chains like BSC, Polygon, and soon Cardano, Alpha Impact is a true innovator in the DeFi space.',
          'The project is building a full suite of DeFi applications with the vision to democratize investing and provide tools for everyone — both small fish and whales. Alpha Impact will cater to crypto investors looking for the best strategies and also traders seeking to monetize their skills.',
          'Users will be able to use several products, including spot, leverage, options, and various DeFi strategies. Thanks to Alpha Impact’s proprietary non-custodial copy-trade technology, users will be able to follow the strategies of other successful traders — while always preserving ownership of their funds.'
        ],
        descriptionImage: '/images/alpha-impact.png',

        // TODO.
        twitterLink: 'https://twitter.com/alphaimpact_fi',
        telegramLink: 'https://t.me/alphaimpact_fi',
        telegramSecondLink: 'https://t.me/alphaimpact_fi_ANN',
        whitePaperLink: 'https://docsend.com/view/p4gnc5tzci4maegr'
      }
    ]
  },
  '0x4': {
    '0x1381b4232523EDa9cfB9dDc4398d20593d4c9B43': [
      {
        tokensAvailable: ethers.utils.parseEther('600000'),
        maxAllocation: ethers.utils.parseEther('0.09087'),
        softCap: ethers.utils.parseEther('13.0855'),
        name: 'Don-key - Guaranteed',
        image: '/images/don-key-crypto.png',
        distribution: 'TBA',
        descriptions: [
          'Yield farming is the craze that’s taken the DeFi world by storm. Over the past 12 months, the number and complexity of yield farms has soared, as have the number of blockchains that now support liquidity mining. Farming is not for the faint-hearted or tech-averse however: it’s a hazardous pursuit that calls for negotiating constantly fluctuating APYs, mitigating impermanent loss, enduring smart contract risk, and frequently entering and exiting positions.',
          'On paper, yield farming sounds like a simple way to earn a passive income from your crypto holdings. In practice, it’s a full-time job with a steep learning curve, executed in a perilous environment where a single mistake can prove costly. But enough about the downside to yield farming: Don-key is all about maximizing the upside by making it easier for humble investors and farmers to generate consistent yield, whatever their ability level.',
          'This will be achieved by bringing liquidity providers and yield farmers together on the Don-key.finance platform, where gamified social trading strategies will incentivize participants to work together and reap the spoils. Don-key will do for yield farming what social (or “copy”) trading has done for cryptocurrency trading.'
        ],
        descriptionImage: '/images/don-key-crypto-small.png',
        twitterLink: 'https://twitter.com/Don_key_finance',
        telegramLink: 'https://t.me/don_key_finance',
        telegramSecondLink: 'https://t.me/don_key_finance_ann',
        whitePaperLink: 'https://don-key.finance/litepaper.pdf'
      },
      {
        tokensAvailable: ethers.utils.parseEther('900000'),
        maxAllocation: ethers.utils.parseEther('0.09087'),
        softCap: ethers.utils.parseEther('19.628'),
        name: 'Don-key - FCFS',
        image: '/images/don-key-crypto.png',
        distribution: 'TBA',
        descriptions: [
          'Yield farming is the craze that’s taken the DeFi world by storm. Over the past 12 months, the number and complexity of yield farms has soared, as have the number of blockchains that now support liquidity mining. Farming is not for the faint-hearted or tech-averse however: it’s a hazardous pursuit that calls for negotiating constantly fluctuating APYs, mitigating impermanent loss, enduring smart contract risk, and frequently entering and exiting positions.',
          'On paper, yield farming sounds like a simple way to earn a passive income from your crypto holdings. In practice, it’s a full-time job with a steep learning curve, executed in a perilous environment where a single mistake can prove costly. But enough about the downside to yield farming: Don-key is all about maximizing the upside by making it easier for humble investors and farmers to generate consistent yield, whatever their ability level.',
          'This will be achieved by bringing liquidity providers and yield farmers together on the Don-key.finance platform, where gamified social trading strategies will incentivize participants to work together and reap the spoils. Don-key will do for yield farming what social (or “copy”) trading has done for cryptocurrency trading.'
        ],
        descriptionImage: '/images/don-key-crypto-small.png',
        twitterLink: 'https://twitter.com/Don_key_finance',
        telegramLink: 'https://t.me/don_key_finance',
        telegramSecondLink: 'https://t.me/don_key_finance_ann',
        whitePaperLink: 'https://don-key.finance/litepaper.pdf'
      }
    ],
    '0x34f5Cf677B563751e7f4Dc5E2258b81Dd2f5Af17': [
      {
        tokensAvailable: ethers.utils.parseEther('1000000'),
        maxAllocation: ethers.utils.parseEther('0.196'),
        softCap: ethers.utils.parseEther('73.5'),
        name: 'Virtue Poker - Guaranteed',
        image: '/images/virtue-poker-small.png',
        distribution: 'TBA',
        descriptions: [
          'Conceived in 2016, Virtue Poker is a ConsenSys incubated, and Pantera backed company that uses the unique features of blockchain technology in conjunction with P2P networking to provide a safe, honest environment to play online poker.',
          'Player funds are never held by Virtue Poker; instead game buy-ins are held in escrow by Ethereum "smart contracts"​ while they play, and payouts occur in 30 seconds or less.',
          'Virtue\'s Poker\'s distributed peer-to-peer shuffling algorithm provides game security, since the deck never resides on a centralized server and is the first and only blockchain based poker platform licensed by the Malta Gaming Authority.'
        ],
        descriptionImage: '/images/virtue-poker.png',

        // TODO.
        twitterLink: 'https://twitter.com/Don_key_finance',
        telegramLink: 'https://t.me/don_key_finance',
        telegramSecondLink: 'https://t.me/don_key_finance_ann',
        whitePaperLink: 'https://don-key.finance/litepaper.pdf'
      },
      {
        tokensAvailable: ethers.utils.parseEther('1000000'),
        maxAllocation: ethers.utils.parseEther('0.196'),
        softCap: ethers.utils.parseEther('73.5'),
        name: 'Virtue Poker - FCFS',
        image: '/images/virtue-poker-small.png',
        distribution: 'TBA',
        descriptions: [
          'Conceived in 2016, Virtue Poker is a ConsenSys incubated, and Pantera backed company that uses the unique features of blockchain technology in conjunction with P2P networking to provide a safe, honest environment to play online poker.',
          'Player funds are never held by Virtue Poker; instead game buy-ins are held in escrow by Ethereum "smart contracts"​ while they play, and payouts occur in 30 seconds or less.',
          'Virtue\'s Poker\'s distributed peer-to-peer shuffling algorithm provides game security, since the deck never resides on a centralized server and is the first and only blockchain based poker platform licensed by the Malta Gaming Authority.'
        ],
        descriptionImage: '/images/virtue-poker.png',

        // TODO.
        twitterLink: 'https://twitter.com/Don_key_finance',
        telegramLink: 'https://t.me/don_key_finance',
        telegramSecondLink: 'https://t.me/don_key_finance_ann',
        whitePaperLink: 'https://don-key.finance/litepaper.pdf'
      }
    ]
  }
};

// Fetch hard-coded total available token amounts.
export const tokensAvailable = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].tokensAvailable;
};

// If available, fetch hard-coded current raise figures.
export const currentRaise = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].currentRaise;
};

// Fetch hard-coded maximum allocation amounts.
export const maxAllocation = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].maxAllocation;
};

// Fetch hard-coded soft cap amounts.
export const softCap = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].softCap;
};

// Fetch hard-coded pool name.
export const name = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].name;
};

// Fetch hard-coded pool image.
export const image = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].image;
};

// Fetch hard-coded pool distribution.
export const distribution = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].distribution;
};

// Fetch hard-coded pool descriptions.
export const descriptions = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].descriptions;
};

// Fetch hard-coded pool description image.
export const descriptionImage = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].descriptionImage;
};

// Fetch hard-coded pool twitter link.
export const twitterLink = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].twitterLink;
};

// Fetch hard-coded pool telegram link.
export const telegramLink = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].telegramLink;
};

// Fetch hard-coded pool telegram second link.
export const telegramSecondLink = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].telegramSecondLink;
};

// Fetch hard-coded pool white paper link.
export const whitePaperLink = (networkId, address, index) => {
  return KNOWN_POOLS[networkId][address][index].whitePaperLink;
};
