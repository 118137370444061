"use strict";

// Import our module information.
import actions from "./actions";
import mutations from "./mutations";

// Prepare state.
const state = () => ({

  // Is the provider currently initializing?
  initializing: false,

  // Does a local Ethereum provider exist which we may try and connect to?
  hasLocalProvider: false,

  // Is the provider able to sign transactions?
  canSign: false,

  // A reference to the Ethers provider; this is either a reference to our local
  // Ethereum provider with a signer, or a read-only Infura provider.
  provider: null,

  // Whether or not the provider's event polling is paused.
  paused: false,

  // The address of the currently active signing account.
  address: null,

  // The connected Ethereum network's ID.
  networkId: null,

  // The connected Ethereum network's current block number.
  blockNumber: 0
});

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
