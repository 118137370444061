<template>
  <div class="p-box card p-p-4 p-text-left">
    <div class="p-d-flex p-jc-between">

      <!-- Include badges indicating the progress of the pools. -->
      <img class="img" :src="pool.image" />
      <Badge
        v-if="!pool.finished && !pool.enabled"
        value="Soon"
        class="p-badge-info"
      />
      <Badge
        v-if="saleProgress.lt(100) && !pool.finished && pool.enabled"
        value="LIVE"
        class="p-badge-danger"
      />
      <Badge
        v-if="!saleProgress.lt(100) && !pool.finished"
        value="Filled"
        class="p-badge-filled"
      />
      <Badge
        v-if="pool.finished"
        value="Closed"
        class="p-badge-closed"
      />
    </div>

    <!-- Display the pool's name and sale price. -->
    <h3>
      {{ pool.name }}
    </h3>
    <div class="tokens">
      {{ pool.tokenForSale.symbol }} / {{ pool.tokenWhichPurchases.symbol }}
    </div>
    <h5>
      {{ `${formatNumber(ethers.utils.formatEther(pool.price), 6)} ${pool.tokenWhichPurchases.symbol} = 1 ${pool.tokenForSale.symbol}` }}
    </h5>

    <!-- Display the pool's progress. -->
    <h5>
      Progress
    </h5>
    <ProgressBar
      :value="saleProgress.toNumber()"
    />
    <div class="progress-value">
      {{ `${formatNumber(saleProgress, 0)}%` }}
      <span>
        (Min 75%)
      </span>
    </div>

    <!-- Display various pool details. -->
    <h5>Total raise</h5>
    <div class="total-raise">
      {{ `${formatNumber(ethers.utils.formatEther(pool.totalRaise), 2)} ETH` }}
    </div>
    <div class="total-raise-subtext">
      ({{ formatNumber(ethToUsd(pool.totalRaise), 0) }} USD)
    </div>

    <div class="p-grid">
      <div class="p-col-4">
        {{ 'Max.' }}
        <div class="value">
          {{ `${formatNumber(ethers.utils.formatEther(pool.maxAllocation), 3)} ${pool.tokenWhichPurchases.symbol}` }}
        </div>
        <div class="value-subtext">
          ({{ formatNumber(ethToUsd(pool.maxAllocation), 0) }} USD)
        </div>
      </div>

      <div class="p-col-4">
        {{ 'Min.' }}
        <div class="value">{{ formatNumber(0, 2) }} ETH</div>
        <div class="value-subtext">
          ({{ formatNumber(ethToUsd(ethers.BigNumber.from(0)), 0) }} USD)
        </div>
      </div>

      <div class="p-col-4">
        Access
        <div class="value">
          {{ !pool.isWhitelist ? 'Public' : 'Private' }}
        </div>
      </div>
    </div>

    <router-link class="link" :to="{ path: `/pool/${pool.contractAddress}/${pool.id}` }">
    </router-link>
  </div>
</template>

<script>
'use strict';

// Imports.
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';
import axios from 'axios';
import { ethers } from 'ethers';
import { formatNumber } from '/src/utility';

// Set up the default component.
export default {
  name: 'PoolCard',
  props: [ 'pool' ],
  components: {
    ProgressBar,
    Badge
  },

  data () {
    return {
      ethers,
      formatNumber,
      ethPrice: 0
    };
  },

  created () {
    this.initializePage();
  },

  computed: {
    saleProgress () {
      return this.pool.currentRaise.mul(100).div(this.pool.totalRaise);
    }
  },

  // Initialize the page with the current price of Ethereum.
  methods: {
    async initializePage () {
      try {
        let priceResponse = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
        );
        this.ethPrice = Math.floor(priceResponse.data.ethereum.usd * 100);
      } catch (error) {
        console.error(error);
      }
    },

    ethToUsd (eth) {
      return ethers.utils.formatEther(eth.mul(this.ethPrice).div(100));
    }
  }
};
</script>

<style lang="scss" scoped>
  .card {
    border-style: solid;
    border-width: 1px;
    border-radius: 24px;
    border-color: rgba(113, 18, 255, 0.2);
    position: relative;
    transition: box-shadow 200ms ease;
    background: #fff;

    &:hover {
      box-shadow: 0 12px 100px 0 rgb(0 0 0 / 20%);
    }
  }

  .img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    margin-top: 1rem;
  }

  .tokens {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1rem;
    opacity: 0.5;
  }

  h5 {
    font-size: 1rem;
    margin-top: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .value {
    font-size: 1rem;
    color: var(--primary-color);
    font-weight: 500;
  }

  .value-subtext {
    font-size: 0.75rem;
    color: var(--subtext-color);
    opacity: 0.5;
    font-weight: 500;
  }

  .total-raise {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 2rem;
  }

  .total-raise-subtext {
    color: var(--secondary-color);
    font-weight: 500;
    opacity: 0.5;
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .p-progressbar {
    margin-bottom: 0.5rem;
  }

  .progress-value {
    margin-bottom: 1rem;
    color: #4ec200;
    font-weight: 500;

    span {
      opacity: 0.5;
      color: #000;
      font-weight: 400;
    }
  }

  .p-badge-closed {
    background-color: rgba(0, 0, 0, 0.2);
    color: #000;
    font-weight: 500;
    font-size: 12px;
    position: relative;
    padding-left: 1.4rem;
    &:before {
      content: '';
      display: block;
      position: absolute;
      margin-right: 0.4rem;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 0.5rem;
      top: 0.43rem;
      background-color: #000;
    }
  }

  .p-badge-filled {
    background-color: rgba(78, 194, 0, 0.2);
    color: #4ec200;
    font-weight: 500;
    font-size: 12px;
    position: relative;
    padding-left: 1.4rem;
    &:before {
      content: '';
      display: block;
      position: absolute;
      margin-right: 0.4rem;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 0.5rem;
      top: 0.43rem;
      background-color: #4ec200;
    }
  }

  .p-badge-danger {
    background-color: rgba(195, 1, 1, 0.2);
    color: #c30101;
    font-weight: 500;
    position: relative;
    padding-left: 1.4rem;
    font-size: 12px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      margin-right: 0.4rem;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 0.5rem;
      top: 0.43rem;
      background-color: #c30101;
      animation: Pulsate 1.2s linear infinite;
    }
  }
  .p-badge-info {
    background-color: rgba(113, 18, 255, 0.2);
    color: var(--primary-color);
    font-weight: 500;
    position: relative;
    font-size: 0.8rem;
  }

  @keyframes Pulsate {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
